<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="primary"
      icon="mdi-vuetify"
      title="How we operate"
      class="text-uppercase white--text"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card-About v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Leases',
          subtitle: '',
          text: 'We provide flexible leases depending on your needs. We understand that everyone has different needs based on their lifestyle. Please let us accomodate you.',
          callout: '01',
        },
        {
          title: 'Maintenance',
          subtitle: '',
          text: "We take care of the maintenance so you don't have to. We all have so much to worry about these days your house should not be one of them.",
          callout: '02',
        },
        {
          title: 'Customer Service',
          subtitle: '',
          text: 'We provide 24/7 customer service. Please contact us anytime by using the contact page.',
          callout: '03',
        },
      ],
    }),
  }
</script>
<style type="css" scoped>
  #about-our-product {
  background-color: darkslateblue !important;
}
</style>
